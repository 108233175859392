<template>
    <div class="page">
        <TitleHeader :title="title" />
        <div class="py-4 px-4 mx-3 rounded-4 mb-3 bg-sub">
            <h5 class="text-primary mb-2">현재 리워드 자산</h5>
            <h1>{{total_point}} P</h1>
        </div>
        <!-- <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금일 리워드 자산 등록</h5>
            <h1>{{today_point}} P</h1>
        </div> -->
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금일 리워드 지급</h5>
            <h1>{{today_point}} P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금월 누적 리워드 지급</h5>
            <h1>{{month_point}} P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금년 누적 리워드 지급</h5>
            <h1>{{year_point}} P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-pink">
           <h5 class="text-pink mb-2">현 잔고 자산</h5>
            <h1>{{my_point}} P</h1>
        </div>
        <div class="bottom_box position-fixed max-w-px-768 bottom-0 start-50 translate-middle-x w-100 p-3 bg-white">
            <div class="flex-between-center gap-2">
                <!-- <router-link to="/" class="w-100 py-2 px-3 btn bg-gray-200">상품권 교환</router-link> -->
                <span class="w-100 py-2 px-3 btn bg-main text-white" @click="$alert('준비중 입니다.')">상품권 교환</span>
                <router-link to="/my-assets/user-list" class="w-100 py-2 px-3 btn btn-outline-main">리워드 내역</router-link>
            </div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'My 자산',
            total_point : '',
            year_point : '',
            month_point : '',
            today_point : '',
            my_point : '',
        }
    },
    mounted(){
        this.GetUserPoint(); 
    },
    methods:{
        GetUserPoint(){

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/mypage/GetUserPoint',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            
                            this.total_point = body.total;
                            this.year_point = body.year;
                            this.month_point = body.month;
                            this.today_point = body.today;
                            this.my_point = body.point;

                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );

        },
    },

}
</script>

<style scoped>
    .page{
        padding-bottom: 75px;
    }
</style>