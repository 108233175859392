<template>
    <div class="page">
        <TitleHeader :title="title" />
        <div class="py-4 px-4 mx-3 rounded-4 mb-3 bg-sub text-center">
            <h5 class="text-primary">금일 리워드 자산편입 내역<br>
            <!-- <span class="fs-px-16 text-body">노래 6곡, 광고 1곡 리워드 자산등록</span> -->
        </h5>
        </div>

        <div class="fs-px-13 px-3">
            <div class="row border-bottom-0 text-center border-top">
                <div class="p-0 flex-center-center border-start border-end border-bottom col-1"><small>순서</small></div>
                <div class="p-0 flex-center-center border-end border-bottom col-6">참여정보</div>
                <div class="p-0 flex-center-center border-end border-bottom col-2">진행시간</div>
                <div class="p-0 flex-center-center border-end border-bottom col-1">점수</div>
                <div class="p-0 flex-center-center border-end border-bottom col-1">금액</div>
                <div class="p-0 flex-center-center col-1 border-end border-bottom">자산등록</div>
            </div>
            <div class="row border-bottom text-center" v-for="(item, index) in list" :key="index" :class="{'bg-pink':item.status !='Y'}">
                <div class="border-end px-0 py-1 border-start col-1"><small>{{index+1}}</small></div>
                <div class="border-end px-0 py-1 col-6">{{item.song_info}}</div>
                <div class="border-end px-0 py-1 col-2">{{item.time}}</div>
                <div class="border-end px-0 py-1 col-1">{{item.score}}</div>
                <div class="border-end px-0 py-1 col-1">{{item.point}}</div>
                <!-- <div class="border-end px-0 py-1 col-2" v-else>{{item.score===100?'정답':'오답'}}</div> -->
                <div class="border-end px-0 py-1 col-1">{{CheckStatus(item.status)}}</div>
            </div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'My 자산',
            list:[]
        }
    },
    mounted(){
        this.GetUserPlayList(); 
    },
    methods:{
        CheckStatus(status){
            if(status =='E'){
                return "성공"
            }else if(status =='B'){
                return "중단"
            }else if(status =='P'){
                return "대기"
            }
        },
        GetUserPlayList(){

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/mypage/GetUserPlayList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            
                            this.list = body.list

                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );

        },
    },

}
</script>

<style scoped>
    .page{
        padding-bottom: 75px;
    }
</style>